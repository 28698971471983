import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// This component checks if the user is authenticated
// If authenticated, it renders the children (protected route)
// If not, it redirects to the signin page
const AuthGuard = ({ children }) => {
  const location = useLocation();

  // Replace this with your actual authentication check
  const isAuthenticated = () => {
    // Example: Check if token exists in localStorage
    const token = localStorage.getItem('token');
    return !!token; // Returns true if token exists, false otherwise
  };

  // If user is not authenticated, redirect to signin page
  // Pass the current location in state so you can redirect back after signin
  if (!isAuthenticated()) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  // If authenticated, render the protected content
  return children;
};

export default AuthGuard;