import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Spinner,
  Alert,
  Image,
  Row,
} from "react-bootstrap";
import favicon from "../../assets/img/metro health hmo final.png";
import { Link, useNavigate } from "react-router-dom";
import { AuthService } from "../../service/AuthService";
import jwt_decode from "jwt-decode";
import Swal from "sweetalert2";
import { RegistrationService } from "../../service/RegistrationService";

export default function SigninComponent(props) {
  const handleSuccess = props.successAction;
  const [enrolleeNumber, setEnrolleeNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [enrolleeRoute, setEnrolleeRoute] = React.useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showError, setShowError] = useState(false);
  const [phoneOtpRoute, setPhoneOtpRoute] = React.useState(false);
  const errorMessages = {
    firstName: "First Name is required",
    lastName: "Last Name is required",
    email: "Please provide a valid email address",
    phoneNumber: "A Valid Phone Number is required",
    password:
      " A Valid Password is required (between 6 to 20 alphanumeric characters)",
    confirmPassword: "Passwords do not match",
  };

  const [formErrors, setFormErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
  });
  const navigate = useNavigate();
  const requestData = {
    enrolleeNumber: enrolleeNumber,
    phoneNumber: phoneNumber,
    useEnrolleeNumberRoute: true,
    usePhoneNumberOtpRoute:false
  };
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const navigateToUrl = (url) => {
    window.open(url, "_blank");
  };

  // }, [navigate(), isLoggedIn,isLogged]);
  // Perform form validation and authentication logic here

  const clickSubmit = () => {
    setIsLoading(true);
    setPasswordError(false);
  };

  const intendedUrl = localStorage.getItem("intendedUrl");

  const handleSubmit = async (event, data) => {
    event.preventDefault(); // Prevent default form submission

    clickSubmit();
    AuthService.loginEnrollee(data)
      .then((response) => {
        //Navigate to the Dashboard page & set context to true
        if (response.status === 200) {
          setStep(2);
          setIsLoading(false);
          Swal.fire(
            "OTP Sent",
            response.data.result,
            "success"
          )
         }

        if (response.status === 401) {
          setPasswordError(
            "Error authenticating this enrollee ID, confirm if it is correct."
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setPasswordError("Error authenticating this enrollee ID");
        // navigate("/forgot-password", { replace: true });
        setIsLoading(false);
      });
  };

  const handleForgotPassword = () => {
// open a new tab to the forgot password page
    window.open("https://www.office.metroflow.com.ng/forgot-password", "_blank");

  }

  const handleConsultantSubmit = async (e) => {
    const postData = {
      email: email,
      password: password,
    };
    e.preventDefault();

    clickSubmit();
    AuthService.loginConsultant(postData)
      .then((response) => {
        //Navigate to the Dashboard page & set context to true
        if (response.status === 200) {
          const decodedToken = jwt_decode(response.data.result.token);
          localStorage.setItem("token", response.data.result.token);
          localStorage.setItem("role", decodedToken.roles[0]);
          const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
          localStorage.setItem("expirationTime", expirationTime.toString());

          // updateUserDetails(newDetails);
          console.log("login success");
          console.log(decodedToken.roles[0]);

          const profile = {
            firstName: response.data.result.firstName,
            lastName: response.data.result.lastName,
            email: decodedToken.sub,
            jobTitle: response.data.result.jobTitle,
            role: decodedToken.roles[0],
            department: response.data.result.department,
            userId: response.data.result.userId,
          };

          localStorage.setItem("profile", JSON.stringify(profile));

          let destination = "/dashboard/consultant";

          if (intendedUrl) {
            destination = intendedUrl;
            console.log(intendedUrl);
            localStorage.removeItem("intendedUrl");
          }

          setTimeout(() => {
            setIsLoading(false);
            if (handleSuccess){
              handleSuccess();
            }
            navigate(destination, { replace: true })

          }, 2000); // Delay of 1 second (1000 milliseconds)
        }
      })
      .catch((error) => {
        if (error.response.data.result === "Bad credentials") {
          setPasswordError(
            "Please reset your password to continue. Type in your official email address and check your mail"
          );
          setIsLoading(false);

          return;
        }

        if (error.response.data.result === "user does not exist") {
          setPasswordError(
            "Please check your email address. No user was found"
          );
          setIsLoading(false);

          return;
        }
        setPasswordError(
          "Connection Error, please try again or check your internet connection"
        );
        // navigate("/forgot-password", { replace: true });
        setIsLoading(false);
      });
  };

  const handleRouteChange = () => {
    setIsLoading(false);
    setPasswordError("");
    setEnrolleeRoute(!enrolleeRoute);
  };

  const handlePrev = () => {
    prevStep();
  };

  const handleNext = () => {
    setShowError(true);
    
      setStep(step + 1);

      return true;
  
  };

  const prevStep = () => {
    setStep(step - 1);
  };



  const handleVerification = (e) => {

   
    setIsLoading(true);
    e.preventDefault();

    clickSubmit();
    AuthService.verifyOtp(otp)
      .then((response) => {
        //Navigate to the Dashboard page & set context to true
        if (response.status === 200) {
          const decodedToken = jwt_decode(response.data.result.token);
          localStorage.setItem("token", response.data.result.token);
          localStorage.setItem(
            "profile",
            JSON.stringify(response.data.result.enrollee)
          );
          localStorage.setItem("role", decodedToken.roles[0]);
          const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds
          localStorage.setItem("expirationTime", expirationTime.toString());


          console.log(decodedToken.roles[0]);
          let destination = "/dashboard/enrollee";

          if (intendedUrl) {
            destination = intendedUrl;
            console.log(intendedUrl);
            localStorage.removeItem("intendedUrl");
          }

          setTimeout(() => {

            setIsLoading(false);
            if (handleSuccess){
              handleSuccess();
            }
            navigate(destination, { replace: true });
          }, 2000); // Delay of 1 second (1000 milliseconds)
        }

        if (response.status === 401) {
          setPasswordError(
            "Error authenticating this enrollee ID, confirm if it is correct."
          );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setPasswordError("Error authenticating this enrollee ID");
        // navigate("/forgot-password", { replace: true });
        setIsLoading(false);
      });
  };

  const handleResend = (e) => {
    const requestData2 = {
      enrolleeNumber: enrolleeNumber,
      phoneNumber: phoneNumber,
      useEnrolleeNumberRoute: true,
      usePhoneNumberOtpRoute:true
    };
    setPhoneOtpRoute(true);
  
    handleSubmit(e,requestData2);
  };
  return (
    <div >
      {enrolleeRoute && (
        <Card className="modal-sign">
          <Card.Header>
            <Image
              src={favicon}
              fluid={true}
              width="250px"
              alt="..."
              className="mx-auto d-block text-center"
              style={{ marginBottom: "30px", alignContent: "" }}
            />
            <Card.Title className="mx-auto d-block text-center">
              Welcome back!{" "}
            </Card.Title>
            <Card.Text className="mx-auto d-block text-center">
              Please insert your enrollee ID to continue.
            </Card.Text>
          </Card.Header>
          <Card.Body>

          {step == 1 && (
            <Form onSubmit={(e) => handleSubmit(e, requestData)}>
              <div className="mb-4">
                <Form.Label> Enrollee ID</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your enrollee ID"
                  value={enrolleeNumber}
                  onChange={(e) => setEnrolleeNumber(e.target.value)}
                />
              </div>

              {!isLoading && (
                <Button type="submit" className="btn-sign">
                  Sign In
                </Button>
              )}
              {isLoading && (
                <Button className="btn-sign">
                  Signing you in...
                  <span />
                  <Spinner size="sm" animation="border" />
                </Button>
              )}
            </Form>
              )}
              {step == 2 && (
                <div className="text-center">
                  {" "}
                  {/* Center the OTP VERIFICATION */}
                  <div className="mb-3">
                    <Form.Label>OTP VERIFICATION</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter the OTP sent to you"
                      onChange={(event) => setOtp(event.target.value)}
                    />
                  </div>
                  <Row className="gx-4">
                    <Col>
                      <Button
                        onClick={handlePrev}
                        variant="secondary"
                        size="sm"
                      >
                        GO BACK
                      </Button>
                    </Col>
                    <Col>
                     
                    
                        <Button onClick={handleResend} variant="info" size="sm">
                        SEND OTP TO MY PHONE
                      </Button>
           
                    </Col>
                  </Row>
                  <br />
                  
                  {!isLoading && (
                    <Button
                    onClick={handleVerification}
                    variant="primary"
                    className="btn-sign"
                  >
                    Verify OTP
                  </Button>
                  )}
                  {isLoading && (
                    <Button className="btn-sign">
                      Processing...
                      <span />
                      <Spinner size="sm" animation="border" />
                    </Button>
                  )}
                </div>
              )}
              <br />

              {showError && (
                <div>
                  {Object.keys(formErrors).map((field) => (
                    <Alert
                      variant="danger"
                      className="alert-outline d-flex align-items-center "
                    >
                      <i className="ri-information-line"></i>
                      <div key={field}>
                        {formErrors[field] && errorMessages[field]}
                      </div>
                    </Alert>
                  ))}
                </div>
              )}
           
          </Card.Body>
          <Card.Footer>
            {passwordError && (
              <Alert
                key={"warning"}
                variant={"warning"}
                className="alert-solid"
              >
                {passwordError}
              </Alert>
            )}
            <Link to="" onClick={handleRouteChange}>
              Sign in as a Medical Professional
            </Link>
            <br />
            <div className="divider">
              <span>GET A HEALTH PLAN</span>
            </div>
            <Row className="gx-2">
              <Col>
                <Button
                  variant=""
                  className="btn-facebook"
                  onClick={() =>
                    navigateToUrl(
                      "https://www.metrohealthhmo.com/self-and-family-health-plan/self-and-family-form-only/"
                    )
                  }
                >
                  Personal/Family Plans
                </Button>
              </Col>
              <Col>
                <Button
                  variant=""
                  className="btn-google"
                  onClick={() =>
                    navigateToUrl(
                      "https://www.metrohealthhmo.com/quote-request-form/"
                    )
                  }
                >
                  Corporate Plans
                </Button>
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      )}
      {!enrolleeRoute && (
        <Card className="modal-sign">
          <Card.Header>
            <Image
              src={favicon}
              fluid={true}
              width="250px"
              alt="..."
              className="mx-auto d-block text-center"
              style={{ marginBottom: "30px", alignContent: "" }}
            />
            <Card.Title className="mx-auto d-block text-center">
              Welcome back!{" "}
            </Card.Title>
            <Card.Text className="mx-auto d-block text-center">
              Enter your credentials
            </Card.Text>
          </Card.Header>
          <Card.Body>
            <Form onSubmit={handleConsultantSubmit}>
              <div className="mb-4">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-4">
                <Form.Label className="d-flex justify-content-between">
                  Password <Link onClick={handleForgotPassword}>Forgot password?</Link>
                </Form.Label>
                <div
                  className="d-flex align-items-center"
                  style={{ position: "relative" }}
                >
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Form.Check
                    type="switch"
                    checked={showPassword}
                    style={{
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      zIndex: 1,
                    }}
                    onChange={togglePasswordVisibility}
                  />
                </div>
              </div>

              {!isLoading && (
                <Button type="submit" className="btn-sign">
                  Sign In
                </Button>
              )}
              {isLoading && (
                <Button className="btn-sign">
                  Signing you in...
                  <span />
                  <Spinner size="sm" animation="border" />
                </Button>
              )}
            </Form>
          </Card.Body>
          <Card.Footer>
            {passwordError && (
              <Alert
                key={"warning"}
                variant={"warning"}
                className="alert-solid"
              >
                {passwordError}
              </Alert>
            )}
            <Link to="" onClick={handleRouteChange}>
              Sign in as an Enrollee
            </Link>
          </Card.Footer>
        </Card>
      )}
    </div>
  );
}
