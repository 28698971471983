import React, { useState } from 'react';
import { Card, Col, Row, Button, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaHospitalSymbol, FaStethoscope } from 'react-icons/fa';
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
// import '../assets/css/app-catalogue.css';

const departmentApps = [
	{
		id: 1,
		name: 'Telemedicine',
		icon: <FaStethoscope className='text-primary' />,
		description:
			'A telemedicine application that allows patients to consult with doctors remotely via voice or video calls.',
		apps: 1,
		link: '/dashboard/enrollee',
		stakeholders: ['Enrollee'],
	},
	{
		id: 2,
		name: 'WTTS',
		icon: <FaHospitalSymbol className='text-success' />,
		description:
			'An automated system for tracking waiting times and patient activities.',
		apps: 1,
		link: '/wtts/dashboard',
		stakeholders: ['Enrollee'],
	},
];

const AppCatalogue = () => {
	const [searchQuery, setSearchQuery] = useState(''); // Search query state
	const filteredDepartments = departmentApps.filter(
		(dept) =>
			dept.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
			dept.description.toLowerCase().includes(searchQuery.toLowerCase())
	); // Filtered departments based on search query

	const highlightMatch = (text) => {
		if (!searchQuery) return text;

		const regex = new RegExp(`(${searchQuery})`, 'gi');
		return text
			.split(regex)
			.map((part, index) =>
				regex.test(part) ? <mark key={index}>{part}</mark> : part
			);
	};

	return (
		<>
			<Sidebar />
			<Header />
			<div className='main main-app p-3 p-lg-4'>
				<div className='catalogue-header mb-5'>
					<h1 className='fw-bold text-primary fs-4'>
						Welcome to your App Catalogue!
					</h1>
					<p className='lead text-muted'>Streamline your Workflow with Ease</p>
					<div className='search-bar mb-4'>
						<input
							type='text'
							className='form-control form-control-lg rounded-pill shadow-sm'
							placeholder='Search applications or departments...'
							value={searchQuery}
							onChange={(e) => setSearchQuery(e.target.value)}
						/>
						<span className='position-absolute top-50 end-0 translate-middle-y me-3'>
							<i className='fas fa-search text-muted'></i>
						</span>
					</div>
				</div>

				{filteredDepartments.length === 0 ? (
					<div className='text-center py-5'>
						<h4 className='text-muted mb-3'>No matching applications found</h4>
						<p className='text-secondary'>
							Try searching for different keywords or check the spelling
						</p>
					</div>
				) : (
					<Row
						xs={1}
						md={2}
						lg={3}
						xl={4}
						className='g-4'>
						{filteredDepartments.map((dept) => (
							<Col key={dept.id}>
								<Card className='h-100 catalogue-card shadow-sm border-0 hover-effect'>
									<Card.Body className='d-flex flex-column'>
										<div className='d-flex align-items-center mb-3'>
											<div className='icon-wrapper bg-light p-3 rounded-circle me-3'>
												{dept.icon}
											</div>
											<div>
												<Card.Title className='mb-0 fw-bold'>
													{highlightMatch(dept.name)}
													{dept.new && (
														<Badge
															bg='success'
															className='ms-2'>
															New
														</Badge>
													)}
												</Card.Title>
												<small className='text-muted'>
													{dept.apps} applications
												</small>
											</div>
										</div>
										<Card.Text className='text-secondary mb-3 flex-grow-1'>
											{highlightMatch(dept.description)}
										</Card.Text>
										<div className='mb-3'>
											<small className='text-muted fw-bold'>
												Stakeholders:
											</small>
											<div className='d-flex flex-wrap gap-2 mt-1'>
												{dept.stakeholders.map((stakeholder, index) => (
													<Badge
														key={index}
														bg='light'
														text='dark'
														className='rounded-pill'>
														{stakeholder}
													</Badge>
												))}
											</div>
										</div>
										<Button
											as={Link}
											to={dept.link}
											variant='outline-primary'
											className='mt-auto align-self-start rounded-pill'>
											Access Suite →
										</Button>
									</Card.Body>
								</Card>
							</Col>
						))}
					</Row>
				)}

				<div className='mt-5 text-center'>
					<p className='text-muted'>Can't find what you're looking for?</p>
					<Button
						variant='primary'
						className='rounded-pill px-4'>
						Request New Application
					</Button>
				</div>
			</div>
		</>
	);
};

export default AppCatalogue;
