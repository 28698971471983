const telemedicineMenu = [
	{
		label: 'My Dashboard',
		link: '/dashboard/enrollee',
		icon: 'ri-suitcase-2-line',
	},
	{
		label: 'Bookings',
		icon: 'ri-calendar-event-line',
		link: '/bookings',
	},
	{
		label: 'Book a Consultation',
		icon: 'ri-calendar-line',
		link: '/slots',
	},
	// {
	// 	label: 'My Beneficiary List',
	// 	link: '/pages/people',
	// 	icon: 'ri-contacts-book-line',
	// },
	// {
	// 	label: 'Report Delay',
	// 	link: '/report-delay',
	// 	icon: 'ri-error-warning-line',
	// },
];

export { telemedicineMenu };
