export const wttsMenu = [
	{
		label: 'Dashboard',
		icon: 'ri-dashboard-line',
		link: '/wtts/dashboard',
	},
	{
		label: 'Appointments',
		icon: 'ri-calendar-check-line',
		link: '/wtts/appointments',
	},
	{
		label: 'Queue Management',
		icon: 'ri-user-follow-line',
		link: '/wtts/queue',
	},
	{
		label: 'Waiting Times',
		icon: 'ri-time-line',
		link: '/wtts/waiting-times',
	},
	{
		label: 'Analytics',
		icon: 'ri-bar-chart-line',
		submenu: [
			{
				label: 'Performance Metrics',
				link: '/wtts/analytics/performance',
			},
			{
				label: 'Wait Time Reports',
				link: '/wtts/analytics/wait-time-reports',
			},
			{
				label: 'Efficiency Stats',
				link: '/wtts/analytics/efficiency',
			},
		],
	},
];
