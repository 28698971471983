import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';
import { EnrolleeService } from '../../service/EnrolleeService';
import SlotDetailsModal from './SlotDetailsModal';
import { showLoadingModal, hideLoadingModal } from '../../logic/Utils';
import WhatsappSupport from '../../components/WhatsappSupport';

import { addDays } from 'date-fns';
import { Button, Col, Modal, Row, Form } from 'react-bootstrap'; // You can use this utility to add days to the current date.
import { telemedicineMenu } from '../../data/TelemedicineMenu';

export default function Slots() {
	useEffect(() => {
		document.body.classList.add('app-calendar');
		return () => {
			document.body.classList.remove('app-calendar');
		};
	}, []);

	const isMobile = window.innerWidth < 768;
	const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : '';
	const [skin, setSkin] = useState(currentSkin);
	const [editMode, setEditMode] = useState(false);
	const [slots, setSlots] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState(null);
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const [showEventModal, setShowEventModal] = useState(false);
	const today = new Date();
	const endRange = addDays(today, 14);

	const handleEventClick = (info) => {
		setSelectedEvent(info.event);
		setShowEventModal(true);
	};

	// toggle sidebar calendar
	const [isSidebarShow, setSidebarShow] = useState(false);

	// Modal
	const [newAppointmentModalShow, setNewAppointmentModalShow] = useState(false);
	const handleNewAppointmentModalClose = () =>
		setNewAppointmentModalShow(false);
	const handleNewAppointmentModalShow = () => setNewAppointmentModalShow(true);

	const handleAppointmentModalClose = () => {
		setShowEventModal(false);
		setSelectedEvent(null);
		setEditMode(false);
	};

	useEffect(() => {
		showLoadingModal('Getting available slots...');
		EnrolleeService.getSlots(
			localStorage.getItem('token'),
			today.toISOString().split('T')[0],
			endRange.toISOString().split('T')[0]
		)
			.then((response) => {
				if (response.status === 200) {
					setSlots(response.data.result);
					hideLoadingModal();
				}
			})
			.catch((error) => {
				console.log(error);
				hideLoadingModal();
			});
	}, []);

	return (
		<React.Fragment>
			<div>
				<Header onSkin={setSkin} />
				<Sidebar
					additionalMenus={[{ label: 'Telemedicine', items: telemedicineMenu }]}
				/>
				<div className='main main-app p-3 p-lg-4'>
					<div className='d-md-flex align-items-center justify-content-between '>
						<div>
							<ol className='breadcrumb fs-sm mb-1'>
								<li className='breadcrumb-item'>
									<Link to='#'>Book A Consultation</Link>
								</li>
								<li
									className='breadcrumb-item active'
									aria-current='page'>
									Available Sessions
								</li>
							</ol>
						</div>
					</div>

					<FullCalendar
						plugins={[
							dayGridPlugin,
							timeGridPlugin,
							interactionPlugin,
							listPlugin,
						]}
						initialView={isMobile ? 'listWeek' : 'dayGridMonth'}
						headerToolbar={{
							left: isMobile ? 'prev,next' : 'prev,next today',
							center: 'title',
							right: isMobile
								? 'listWeek'
								: 'listWeek,timeGridDay,timeGridWeek,dayGridMonth',
						}}
						eventSources={slots}
						eventClick={handleEventClick}
						validRange={{
							start: today.toISOString().split('T')[0], // Current day in 'YYYY-MM-DD' format
							end: endRange.toISOString().split('T')[0], // 30 days from today
						}}
						customButtons={{
							custom1: {
								icon: 'chevron-left',
								click: function () {
									setSidebarShow(!isSidebarShow);
								},
							},
						}}
					/>

					{/* New Appointment Modal */}
					<Modal
						className='modal-event'
						show={newAppointmentModalShow}
						onHide={handleNewAppointmentModalClose}
						centered>
						<Modal.Header closeButton>
							<Modal.Title>Book a Session</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form>
								<Row className='g-3 mb-3'>
									<Col
										xs='7'
										md='8'>
										<Form.Group controlId='startDate'>
											<Form.Label>Start Date</Form.Label>
											<h5>
												{startDate
													? startDate.toLocaleDateString()
													: 'No date selected'}
											</h5>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group controlId='startTime'>
											<Form.Label>Start Time</Form.Label>
											<h5>
												{startDate
													? startDate.toLocaleTimeString([], {
															hour: '2-digit',
															minute: '2-digit',
													  })
													: 'No time selected'}
											</h5>
										</Form.Group>
									</Col>
								</Row>
								<Row className='g-3 mb-3'>
									<Col
										xs='7'
										md='8'>
										<Form.Group controlId='endDate'>
											<Form.Label>End Date</Form.Label>
											<h5>
												{endDate
													? endDate.toLocaleDateString()
													: 'No date selected'}
											</h5>
										</Form.Group>
									</Col>
									<Col>
										<Form.Group controlId='endTime'>
											<Form.Label>End Time</Form.Label>
											<h5>
												{endDate
													? endDate.toLocaleTimeString([], {
															hour: '2-digit',
															minute: '2-digit',
													  })
													: 'No time selected'}
											</h5>
										</Form.Group>
									</Col>
								</Row>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<Button
								variant=''
								className='btn-white'
								onClick={handleNewAppointmentModalClose}>
								Close
							</Button>
							<Button
								variant='primary'
								onClick={handleNewAppointmentModalClose}>
								Add Event
							</Button>
						</Modal.Footer>
					</Modal>

					{/* Appointment Modal */}
					<SlotDetailsModal
						show={showEventModal}
						onHide={handleAppointmentModalClose}
						selectedEvent={selectedEvent}
						editMode={editMode}
						setEditMode={setEditMode}
					/>
				</div>
			</div>

			<WhatsappSupport />
		</React.Fragment>
	);
}
