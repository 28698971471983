import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import userAvatar from '../assets/img/img1.jpg';
import favicon from '../assets/img/metro health hmo final.png';
import { Image } from 'react-bootstrap';

import {
	dashboardAdminMenu,
	dashboardMenuEnrollee,
	metroLinks,
} from '../data/Menu';

export default class Sidebar extends Component {
	toggleFooterMenu = (e) => {
		e.preventDefault();

		let parent = e.target.closest('.sidebar');
		parent.classList.toggle('footer-menu-show');
	};

	render() {
		let imageUrl = undefined;
		let role = localStorage.getItem('role');
		let profileData = {};
		const profile = localStorage.getItem('profile');
		profileData = JSON.parse(profile);

		// Base menus that should always be present
		const baseMetroLinks = metroLinks;
		const baseDashboardMenu =
			role !== 'ENROLLEE' ? dashboardAdminMenu : dashboardMenuEnrollee;

		// Get menus from props with defaults
		const {
			primaryMenuLabel = 'Dashboard',
			secondaryMenuLabel = 'Metro Links',
			additionalMenus = [], // New prop for additional menus
		} = this.props;

		if (profileData != null) {
			imageUrl = profileData.imageUrl;
		}

		return (
			<div className='sidebar'>
				<div className='sidebar-header'>
					<Image
						src={favicon}
						fluid={true}
						width='150px'
						alt='Metro Health Logo'
						className='mx-auto d-block text-center'
						style={{
							marginBottom: '30px',
							marginTop: '30px',
						}}
					/>
				</div>
				<PerfectScrollbar
					className='sidebar-body'
					ref={(ref) => (this._scrollBarRef = ref)}>
					<SidebarMenu
						onUpdateSize={() => this._scrollBarRef.updateScroll()}
						role={role}
						baseDashboardMenu={baseDashboardMenu}
						baseMetroLinks={baseMetroLinks}
						primaryMenuLabel={primaryMenuLabel}
						secondaryMenuLabel={secondaryMenuLabel}
						additionalMenus={additionalMenus}
					/>
				</PerfectScrollbar>
				<div className='sidebar-footer'>
					<div className='sidebar-footer-top'>
						<div className='sidebar-footer-thumb'>
							<img
								src={imageUrl === undefined ? userAvatar : imageUrl}
								alt='User Avatar'
							/>
						</div>
						<div className='sidebar-footer-body'>
							<h6>
								<Link to='../pages/profile.html'>
									{profileData
										? profileData.firstName + ' ' + profileData.lastName
										: ''}
								</Link>
							</h6>
							<p>
								{role ? (role === 'ENROLLEE' ? 'ENROLLEE' : 'CONSULTANT') : ''}
							</p>
						</div>
						<Link
							onClick={this.toggleFooterMenu}
							to=''
							className='dropdown-link'>
							<i className='ri-arrow-down-s-line'></i>
						</Link>
					</div>
				</div>

			</div>
		);
	}
}

class SidebarMenu extends Component {
	populateMenu = (m) => {
		const menu = m.map((menuItem, key) => {
			let submenuItems;
			if (menuItem.submenu) {
				submenuItems = menuItem.submenu.map((sm, subKey) => {
					return (
						<NavLink
							to={sm.link}
							className='nav-sub-link'
							key={subKey}>
							{sm.label}
						</NavLink>
					);
				});
			}

			return (
				<li
					key={key}
					className='nav-item'>
					{!submenuItems ? (
						<NavLink
							to={menuItem.link}
							className='nav-link'>
							<i className={menuItem.icon}></i> <span>{menuItem.label}</span>
						</NavLink>
					) : (
						<div
							onClick={this.toggleSubMenu}
							className='nav-link has-sub'>
							<i className={menuItem.icon}></i> <span>{menuItem.label}</span>
						</div>
					)}
					{menuItem.submenu && (
						<nav className='nav nav-sub'>{submenuItems}</nav>
					)}
				</li>
			);
		});

		return <ul className='nav nav-sidebar'>{menu}</ul>;
	};

	// Toggle menu group
	toggleMenu = (e) => {
		e.preventDefault();

		let parent = e.target.closest('.nav-group');
		parent.classList.toggle('show');

		this.props.onUpdateSize();
	};

	// Toggle submenu while closing siblings' submenu
	toggleSubMenu = (e) => {
		e.preventDefault();

		let parent = e.target.closest('.nav-item');
		let node = parent.parentNode.firstChild;

		while (node) {
			if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
				node.classList.remove('show');
			node = node.nextElementSibling || node.nextSibling;
		}

		parent.classList.toggle('show');

		this.props.onUpdateSize();
	};

	render() {
		const {
			baseDashboardMenu,
			baseMetroLinks,
			additionalMenus,
			primaryMenuLabel,
			secondaryMenuLabel,
		} = this.props;

		return (
			<React.Fragment>
				<div>
					{/* Render any additional menus passed as props */}
					{additionalMenus.map((menu, index) => (
						<div
							className='nav-group show'
							key={`additional-menu-${index}`}>
							<div
								className='nav-label'
								onClick={this.toggleMenu}>
								{menu.label}
							</div>
							{this.populateMenu(menu.items)}
						</div>
					))}
					{/* Always show Dashboard menu */}
					<div className='nav-group show'>
						<div
							className='nav-label'
							onClick={this.toggleMenu}>
							{primaryMenuLabel}
						</div>
						{this.populateMenu(baseDashboardMenu)}
					</div>

					{/* Always show Metro Links menu */}
					<div className='nav-group show'>
						<div
							className='nav-label'
							onClick={this.toggleMenu}>
							{secondaryMenuLabel}
						</div>
						{this.populateMenu(baseMetroLinks)}
					</div>
				</div>
			</React.Fragment>
		);
	}
}

window.addEventListener('click', function (e) {
	// Close sidebar footer menu when clicked outside of it
	let tar = e.target;
	let sidebar = document.querySelector('.sidebar');
	if (!tar.closest('.sidebar-footer') && sidebar) {
		sidebar.classList.remove('footer-menu-show');
	}

	// Hide sidebar offset when clicked outside of sidebar
	if (!tar.closest('.sidebar') && !tar.closest('.menu-link')) {
		document.querySelector('body').classList.remove('sidebar-show');
	}
});

window.addEventListener('load', function () {
	let skinMode = localStorage.getItem('sidebar-skin');
	let HTMLTag = document.querySelector('html');

	if (skinMode) {
		HTMLTag.setAttribute('data-sidebar', skinMode);
	}
});
