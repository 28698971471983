import React, { useEffect, useState } from 'react';
import Header from '../layouts/Header';
import Sidebar from '../layouts/Sidebar';
import Joyride from 'react-joyride';
import { Card, Col, Nav, Row, Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import blog from '../assets/img/blog.png';
import ppp from '../assets/img/ppp.jpg';
import { telemedicineMenu } from '../data/TelemedicineMenu';
import { AppointmentService } from '../service/AppointmentService';
import BookingModalView from '../pages/bookings/BookingModalView';
import BookingTable from '../components/BookingTable';
import PlaceholderComponent from '../components/PlaceholderComponent';
// import WhatsappSupport from '../components/WhatsappSupport';

export default function EnrolleeDashboard() {
	// Helper function: Remove unwanted phrases and truncate text to a max length
	const truncateText = (text, maxLength) => {
		if (!text) return '';
		const cleanedText = text
			.replace(/data consent/gi, '')
			.replace(/international health plan form/gi, '')
			.replace(/diaspora health plan form/gi, '');
		const plainText = cleanedText.replace(/<[^>]+>/g, '');
		return plainText.length > maxLength
			? plainText.substring(0, maxLength) + '...'
			: plainText;
	};

	// Greeting & profile
	const [greeting, setGreeting] = useState('');
	const userDetailsJSON = localStorage.getItem('profile');
	const profileData = JSON.parse(userDetailsJSON);

	// Booking and loading states
	const [loading, setLoading] = useState(false);
	const [bookings, setBookingsList] = useState([]);
	const [modalState, setModalState] = useState(false);
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [showBookings, setShowBookings] = useState(false);

	// Blog posts state
	const [blogPosts, setBlogPosts] = useState([]);
	const [blogLoading, setBlogLoading] = useState(true);

	// Plans state (pages from WP that represent plans)
	const [planPages, setPlanPages] = useState([]);
	const [planLoading, setPlanLoading] = useState(true);

	// Fetch blog posts (with _embed for featured images)
	useEffect(() => {
		fetch('https://metrohealthhmo.com/wp-json/wp/v2/posts?_embed')
			.then((response) => response.json())
			.then((data) => {
				setBlogPosts(data);
				setBlogLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching blog posts:', error);
				setBlogLoading(false);
			});
	}, []);

	// Fetch plan pages (with _embed) and then filter unwanted plans
	useEffect(() => {
		fetch('https://metrohealthhmo.com/wp-json/wp/v2/pages?_embed')
			.then((response) => response.json())
			.then((data) => {
				setPlanPages(data);
				setPlanLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching plan pages:', error);
				setPlanLoading(false);
			});
	}, []);

	// Filter out plans containing unwanted terms in the title
	const filteredPlanPages = planPages.filter((plan) => {
		const title = plan.title.rendered.toLowerCase();
		return (
			!title.includes('data consent') &&
			!title.includes('international health plan form') &&
			!title.includes('diaspora health plan form')
		);
	});

	const handleBookingView = (booking) => {
		setSelectedBooking(booking);
		setModalState(true);
	};

	const handleClose = () => {
		setModalState(false);
		setSelectedBooking(null);
	};

	const makePhoneCall = () => {
		const number = '08000432584';
		const link = document.createElement('a');
		link.href = `tel:${number}`;
		link.style.display = 'none';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	// Fetch bookings
	useEffect(() => {
		AppointmentService.getMyBookings(localStorage.getItem('token'))
			.then((response) => {
				if (response.status === 200) {
					setBookingsList(response.data.result);
					setShowBookings(true);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const refreshBookings = () => {
		setShowBookings(false);
		AppointmentService.getMyBookings(localStorage.getItem('token'))
			.then((response) => {
				if (response.status === 200) {
					setBookingsList(response.data.result);
					setShowBookings(true);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log(error);
			});
	};

	// Emoji component
	const Emoji = ({ symbol, label }) => (
		<span
			role='img'
			aria-label={label}>
			{symbol}
		</span>
	);

	// Set greeting based on current hour
	useEffect(() => {
		const currentHour = new Date().getHours();
		if (currentHour < 12) {
			setGreeting('Good Morning');
		} else if (currentHour < 18) {
			setGreeting('Good Afternoon');
		} else {
			setGreeting('Good Evening');
		}
	}, []);

	///// Skin Switch /////
	const currentSkin = localStorage.getItem('skin-mode') ? 'dark' : '';
	const [skin, setSkin] = useState(currentSkin);

	const switchSkin = (skin) => {
		if (skin === 'dark') {
			const btnWhite = document.getElementsByClassName('btn-white');
			for (const btn of btnWhite) {
				btn.classList.add('btn-outline-primary');
				btn.classList.remove('btn-white');
			}
		} else {
			const btnOutlinePrimary = document.getElementsByClassName(
				'btn-outline-primary'
			);
			for (const btn of btnOutlinePrimary) {
				btn.classList.remove('btn-outline-primary');
				btn.classList.add('btn-white');
			}
		}
	};

	switchSkin(skin);
	useEffect(() => {
		switchSkin(skin);
	}, [skin]);

	const [steps] = useState([
		{ target: '#bookingsTable', content: 'These are your consultations.' },
		{
			target: '#slots',
			content:
				'You can book a video consultation here by clicking on the button.',
		},
		{ target: '#tollfree', content: 'Talk to a Doctor (Toll-Free)' },
	]);

	return (
		<React.Fragment>
			<Joyride
				steps={steps}
				continuous={true}
				showProgress={true}
				showSkipButton={true}
			/>
			<Header onSkin={setSkin} />
			<Sidebar
				additionalMenus={[{ label: 'Telemedicine', items: telemedicineMenu }]}
			/>
			<div className='main main-app p-3 p-lg-4'>
				{/* Dashboard Header Section */}
				<div className='d-sm-flex align-items-center justify-content-between mb-4'>
					<div>
						<ol className='breadcrumb fs-sm mb-1'>
							<li className='breadcrumb-item'>
								<Link to='#'>Dashboard</Link>
							</li>
							<li
								className='breadcrumb-item active'
								aria-current='page'>
								Enrollee
							</li>
						</ol>
						<br />
						<h4 className='main-title mb-0'>
							{greeting} {profileData ? profileData.firstName : ''}{' '}
							<Emoji
								symbol='👋'
								label='smiley'
							/>
						</h4>
						<br />
						<h6>Welcome to your Dashboard</h6>
					</div>
				</div>

				{/* Top Section: Profile Details & Bookings */}
				{!loading && (
					<Row className='g-3'>
						<Col xl='5'>
							<Row className='g-3'>
								<Col
									xs='6'
									md='3'
									xl='6'>
									<Card className='card-one card-product h-100'>
										<Card.Body className='p-3'>
											<div className='d-flex align-items-center justify-content-between mb-5'>
												<div className='card-icon'>
													<i className='ri-heart-add-fill'></i>
												</div>
											</div>
											<h2 className='card-value ls--1'>
												{profileData.planName}
											</h2>
											<label className='card-label fw-medium text-dark'>
												Health Plan Coverage
											</label>
											<span className='d-flex gap-1 fs-xs'>
												<span
													className={
														profileData.isterminated == 'true'
															? 'd-flex align-items-center text-danger'
															: 'd-flex align-items-center text-success'
													}>
													<span className='ff-numerals'>
														{profileData.isterminated == 'true'
															? 'STATUS: INACTIVE'
															: 'STATUS: ACTIVE'}
													</span>
													<i
														className={
															profileData.isterminated == 'true'
																? 'ri-arrow-down-line'
																: 'ri-arrow-up-line'
														}></i>
												</span>
											</span>
										</Card.Body>
									</Card>
								</Col>
								<Col
									xs='6'
									md='3'
									xl='6'>
									<Card className='card-one card-product h-100'>
										<Card.Body className='p-3'>
											<div className='d-flex align-items-center justify-content-between mb-5'>
												<div className='card-icon'>
													<i className='ri-empathize-fill'></i>
												</div>
											</div>
											<h2 className='card-value ls--1'>
												{profileData.enrolleeId != null
													? profileData.enrolleeId
													: 'Not Set'}
											</h2>
											<label className='card-label fw-medium text-dark'>
												Enrollee Number
											</label>
											<span className='d-flex gap-1 fs-xs'></span>
										</Card.Body>
									</Card>
								</Col>
								<Col
									xs='6'
									md='3'
									xl='6'>
									<Card className='card-one card-product h-100'>
										<Card.Body className='p-3'>
											<div className='d-flex align-items-center justify-content-between mb-5'>
												<div className='card-icon'>
													<i className='ri-health-book-fill'></i>
												</div>
											</div>
											<h2 className='card-value ls--1'>Coverage Details</h2>
											{/* <label className="card-label fw-medium text-dark">Coverage Stattistica</label> */}
											<span className='d-flex gap-1 fs-xs'>
												<span
													className={'d-flex align-items-center text-success'}>
													<Row>
														<span className='ff-numerals'>
															Starting Date: <br></br>
															{profileData.effectiveDate != null
																? profileData.effectiveDate
																: 'Not Set'}{' '}
														</span>
													</Row>
												</span>

												<span
													className={
														'd-flex align-items-center text-danger'
													}></span>
											</span>
										</Card.Body>
									</Card>
								</Col>
								<Col
									xs='6'
									md='3'
									xl='6'>
									<Card className='card-one card-product h-100'>
										<Card.Body className='p-3'>
											<div className='d-flex align-items-center justify-content-between mb-5'>
												<div className='card-icon'>
													<i className='ri-first-aid-kit-fill'></i>
												</div>
											</div>
											<h2 className='card-value ls--1'>
												{' '}
												Provider Information
											</h2>
											<label className='card-label fw-medium text-dark'>
												{profileData.providerName != null
													? profileData.providerName
													: 'Not Set'}
											</label>
										</Card.Body>
									</Card>
								</Col>
							</Row>
						</Col>
						<Col xl='7'>
							<Row className='g-2'>
								<Card
									id='bookingsTable'
									className='card-one'>
									<Card.Header>
										<Card.Title as='h6'>Latest Booked Consultations</Card.Title>
										<Nav className='nav-icon nav-icon-sm ms-auto'>
											<Nav.Link href=''>
												<i className='ri-refresh-line'></i>
											</Nav.Link>
											<Nav.Link href=''>
												<i className='ri-more-2-fill'></i>
											</Nav.Link>
										</Nav>
									</Card.Header>
									<Card.Body className='p-3'>
										{showBookings ? (
											<BookingTable
												id={'bookings'}
												bookings={bookings}
												role={'ENROLLEE'}
												handleBookingView={handleBookingView}
											/>
										) : (
											<PlaceholderComponent type='table' />
										)}

										{bookings.length === 0 && showBookings && (
											<h6>No Upcoming Appointment</h6>
										)}
									</Card.Body>
								</Card>

								<Row className='g-3 '>
									<Col
										id='slots'
										xs='6'
										md='3'
										xl='6'>
										<Link to={'/slots'}>
											<div className={'task-category p-3 bg-success'}>
												<h5 className='category-title mb-1'>
													{'Book a Video Consultation'}
												</h5>
											</div>
										</Link>
									</Col>
									<Col
										id='tollfree'
										xs='6'
										md='3'
										xl='6'>
										<Link onClick={makePhoneCall}>
											<div className={'task-category p-3 bg-primary'}>
												<h5 className='category-title  mb-1'>
													Talk to a Doctor (Toll-Free)
												</h5>
											</div>
										</Link>
									</Col>
								</Row>
							</Row>
						</Col>

						<Col xl='8'></Col>
						{/* Bottom Section: Plans & Blog Carousels */}
						<Row className='g-3 mt-4'>
							<Col
								xs={12}
								xl={6}>
								<Card className='card-one'>
									<Card.Header>
										<Card.Title as='h6'>
											Explore Our Recommended Health Plan
										</Card.Title>
										<Nav className='nav-icon nav-icon-sm ms-auto'>
											<Nav.Link href='#'>
												<i className='ri-refresh-line'></i>
											</Nav.Link>
											<Nav.Link href='#'>
												<i className='ri-more-2-fill'></i>
											</Nav.Link>
										</Nav>
									</Card.Header>
									<Card.Body className='p-3'>
										{planLoading ? (
											<PlaceholderComponent type='card' />
										) : filteredPlanPages.length > 0 ? (
											<Carousel
												indicators={false}
												interval={3000}
												nextIcon={
													<div
														style={{
															position: 'absolute',
															right: '10px',
															top: '50%',
															transform: 'translateY(-50%)',
															backgroundColor: 'rgba(255,255,255,0.7)',
															borderRadius: '50%',
															width: '40px',
															height: '40px',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															cursor: 'pointer',
															zIndex: 1,
														}}>
														<span style={{ fontSize: '1.5rem', color: '#000' }}>
															&#10095;
														</span>
													</div>
												}
												prevIcon={
													<div
														style={{
															position: 'absolute',
															left: '10px',
															top: '50%',
															transform: 'translateY(-50%)',
															backgroundColor: 'rgba(255,255,255,0.7)',
															borderRadius: '50%',
															width: '40px',
															height: '40px',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															cursor: 'pointer',
															zIndex: 1,
														}}>
														<span style={{ fontSize: '1.5rem', color: '#000' }}>
															&#10094;
														</span>
													</div>
												}>
												{filteredPlanPages.map((plan, index) => (
													<Carousel.Item key={index}>
														<Row className='g-0'>
															<Col md='4'>
																{plan._embedded &&
																plan._embedded['wp:featuredmedia'] ? (
																	<img
																		src={
																			plan._embedded['wp:featuredmedia'][0]
																				.source_url
																		}
																		className='d-block w-100'
																		alt={plan.title.rendered}
																		style={{
																			height: '200px',
																			objectFit: 'cover',
																		}}
																	/>
																) : (
																	<img
																		src={ppp}
																		className='d-block w-100'
																		alt='Plan'
																		style={{
																			height: '200px',
																			objectFit: 'cover',
																		}}
																	/>
																)}
															</Col>
															<Col md='8'>
																<div className='p-3'>
																	<h6
																		dangerouslySetInnerHTML={{
																			__html: plan.title.rendered,
																		}}
																	/>
																	<p>
																		{truncateText(plan.excerpt.rendered, 100)}
																	</p>
																	<a
																		href={plan.link}
																		target='_blank'
																		rel='noopener noreferrer'>
																		Learn More
																	</a>
																</div>
															</Col>
														</Row>
													</Carousel.Item>
												))}
											</Carousel>
										) : (
											<h6>No Plans Available</h6>
										)}
									</Card.Body>
								</Card>
							</Col>
							<Col
								xs={12}
								xl={6}>
								<Card className='card-one'>
									<Card.Header>
										<Card.Title as='h6'>Latest Blog Post</Card.Title>
										<Nav className='nav-icon nav-icon-sm ms-auto'>
											<Nav.Link href=''>
												<i className='ri-refresh-line'></i>
											</Nav.Link>
											<Nav.Link href=''>
												<i className='ri-more-2-fill'></i>
											</Nav.Link>
										</Nav>
									</Card.Header>
									<Card.Body className='p-3'>
										{blogLoading ? (
											<PlaceholderComponent type='card' />
										) : blogPosts.length > 0 ? (
											<Carousel
												indicators={false}
												interval={3000}
												nextIcon={
													<div
														style={{
															position: 'absolute',
															right: '10px',
															top: '50%',
															transform: 'translateY(-50%)',
															backgroundColor: 'rgba(255,255,255,0.7)',
															borderRadius: '50%',
															width: '40px',
															height: '40px',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															cursor: 'pointer',
															zIndex: 1,
														}}>
														<span style={{ fontSize: '1.5rem', color: '#000' }}>
															&#10095;
														</span>
													</div>
												}
												prevIcon={
													<div
														style={{
															position: 'absolute',
															left: '10px',
															top: '50%',
															transform: 'translateY(-50%)',
															backgroundColor: 'rgba(255,255,255,0.7)',
															borderRadius: '50%',
															width: '40px',
															height: '40px',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															cursor: 'pointer',
															zIndex: 1,
														}}>
														<span style={{ fontSize: '1.5rem', color: '#000' }}>
															&#10094;
														</span>
													</div>
												}>
												{blogPosts.map((post, index) => (
													<Carousel.Item key={index}>
														<Row className='g-0'>
															<Col md='4'>
																{post._embedded &&
																post._embedded['wp:featuredmedia'] ? (
																	<img
																		src={
																			post._embedded['wp:featuredmedia'][0]
																				.source_url
																		}
																		className='d-block w-100'
																		alt={post.title.rendered}
																		style={{
																			height: '200px',
																			objectFit: 'cover',
																		}}
																	/>
																) : (
																	<img
																		src={blog}
																		className='d-block w-100'
																		alt='Blog Post'
																		style={{
																			height: '200px',
																			objectFit: 'cover',
																		}}
																	/>
																)}
															</Col>
															<Col md='8'>
																<div className='p-3'>
																	<h6
																		dangerouslySetInnerHTML={{
																			__html: post.title.rendered,
																		}}
																	/>
																	<p>
																		{truncateText(post.excerpt.rendered, 100)}
																	</p>
																	<a
																		href={post.link}
																		target='_blank'
																		rel='noopener noreferrer'>
																		Read More
																	</a>
																</div>
															</Col>
														</Row>
													</Carousel.Item>
												))}
											</Carousel>
										) : (
											<h6>No Blog Posts Available</h6>
										)}
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</Row>
				)}

				{modalState && selectedBooking && (
					<BookingModalView
						booking={selectedBooking}
						modalInitialState={modalState}
						handleModalClose={handleClose}
						refreshAction={refreshBookings}
					/>
				)}
			</div>
		</React.Fragment>
	);
}
