import React, { useState, useEffect } from 'react';
import Header from '../../layouts/Header';
import Sidebar from '../../layouts/Sidebar';
import { wttsMenu } from '../../data/WttsMenu';
import {
	Breadcrumb,
	Form,
	Button,
	Card,
	Row,
	Col,
	Badge,
} from 'react-bootstrap';
import {
	LineChart,
	Line,
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';
import {
	Clock,
	Hospital,
	ExclamationTriangle,
	Stopwatch,
	Calendar3,
	Person,
	Building,
	Filter,
	Search,
} from 'react-bootstrap-icons';

// Reusable StatCard component
const StatCard = ({ title, value, icon, color, subtitle }) => {
	return (
		<Card className='h-100 shadow-sm'>
			<Card.Body className='d-flex flex-column'>
				<div className='d-flex align-items-center mb-3'>
					<div
						className='d-flex align-items-center justify-content-center me-3'
						style={{
							width: '48px',
							height: '48px',
							borderRadius: '12px',
							backgroundColor: color,
							color: '#ffffff',
						}}>
						{icon}
					</div>
					<div>
						<h6 className='mb-0 text-muted'>{title}</h6>
						<h4 className='mb-0 fw-bold'>{value}</h4>
						{subtitle && <small className='text-muted'>{subtitle}</small>}
					</div>
				</div>
			</Card.Body>
		</Card>
	);
};

// Reusable DateRangePicker component
const DateRangePicker = ({ onDateChange }) => {
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');

	const handleApplyFilter = () => {
		onDateChange({ startDate, endDate });
	};

	const handleClearFilter = () => {
		setStartDate('');
		setEndDate('');
		onDateChange({ startDate: '', endDate: '' });
	};

	return (
		<Card className='mb-4 shadow-sm'>
			<Card.Body>
				<Card.Title className='d-flex align-items-center mb-3'>
					<Calendar3
						className='me-2'
						size={20}
					/>
					Date Filter
				</Card.Title>
				<Row className='g-2'>
					<Col md={5}>
						<Form.Group>
							<Form.Label>Start Date</Form.Label>
							<Form.Control
								type='date'
								value={startDate}
								onChange={(e) => setStartDate(e.target.value)}
							/>
						</Form.Group>
					</Col>
					<Col md={5}>
						<Form.Group>
							<Form.Label>End Date</Form.Label>
							<Form.Control
								type='date'
								value={endDate}
								onChange={(e) => setEndDate(e.target.value)}
							/>
						</Form.Group>
					</Col>
					<Col
						md={2}
						className='d-flex align-items-end'>
						<div className='d-flex gap-2'>
							<Button
								variant='primary'
								onClick={handleApplyFilter}
								className='d-flex align-items-center'>
								<Filter
									size={16}
									className='me-1'
								/>{' '}
								Apply
							</Button>
							<Button
								variant='outline-secondary'
								onClick={handleClearFilter}
								size='sm'>
								Clear
							</Button>
						</div>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	);
};

// Main Dashboard component
const Dashboard = () => {
	// Extended dummy data for prototyping
	const dummyData = {
		visitTrend: [
			{ month: 'Jan', visits: 12, avgWaitTime: 35 },
			{ month: 'Feb', visits: 19, avgWaitTime: 42 },
			{ month: 'Mar', visits: 13, avgWaitTime: 28 },
			{ month: 'Apr', visits: 22, avgWaitTime: 31 },
			{ month: 'May', visits: 28, avgWaitTime: 25 },
			{ month: 'Jun', visits: 25, avgWaitTime: 29 },
			{ month: 'Jul', visits: 16, avgWaitTime: 33 },
			{ month: 'Aug', visits: 27, avgWaitTime: 30 },
		],
		hospitalPerformance: [
			{ name: 'General Hospital', avgWaitTime: 45, onTimeRate: 75 },
			{ name: 'St. Mary Medical', avgWaitTime: 32, onTimeRate: 85 },
			{ name: 'Community Health', avgWaitTime: 55, onTimeRate: 62 },
			{ name: 'University Medical', avgWaitTime: 28, onTimeRate: 90 },
			{ name: 'Memorial Hospital', avgWaitTime: 38, onTimeRate: 78 },
		],
		visitHistory: [
			{
				id: 'V001',
				date: '2025-01-15',
				provider: 'Dr. Smith',
				hospital: 'General Hospital',
				scheduledTime: '09:00',
				actualTime: '09:15',
				duration: 30,
				waitTime: 15,
				service: 'Consultation',
				status: 'completed',
			},
			{
				id: 'V002',
				date: '2025-02-20',
				provider: 'Dr. Jones',
				hospital: 'St. Mary Medical',
				scheduledTime: '14:30',
				actualTime: '14:35',
				duration: 45,
				waitTime: 5,
				service: 'Routine Check-up',
				status: 'completed',
			},
			{
				id: 'V003',
				date: '2025-03-10',
				provider: 'Dr. Smith',
				hospital: 'General Hospital',
				scheduledTime: '11:00',
				actualTime: '11:40',
				duration: 25,
				waitTime: 40,
				service: 'Consultation',
				status: 'completed',
			},
			{
				id: 'V004',
				date: '2025-04-05',
				provider: 'Dr. Lee',
				hospital: 'Community Health',
				scheduledTime: '16:15',
				actualTime: '17:00',
				duration: 40,
				waitTime: 45,
				service: 'Emergency',
				status: 'completed',
			},
			{
				id: 'V005',
				date: '2025-05-22',
				provider: 'Dr. Williams',
				hospital: 'University Medical',
				scheduledTime: '10:30',
				actualTime: '10:35',
				duration: 35,
				waitTime: 5,
				service: 'Follow-up',
				status: 'completed',
			},
		],
	};

	// Extended statistics for dashboard KPIs
	const dummyStats = {
		totalTimeSpent: 560, // in minutes
		totalWaitTime: 120, // in minutes
		totalVisits: 15,
		longestWaitHospital: 'Community Health',
		providerWithMostDelays: {
			name: 'Dr. Smith',
			delayPercent: 35,
		},
		averageVisitDuration: 38, // in minutes
		averageWaitTime: 22, // in minutes
		onTimePerformance: 68, // percentage
	};

	// State management
	const [stats, setStats] = useState(null);
	const [filteredData, setFilteredData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState('');
	const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });

	// Simulate fetching data from an API
	useEffect(() => {
		setTimeout(() => {
			const combinedData = { ...dummyData, ...dummyStats };
			setStats(combinedData);
			setFilteredData(combinedData);
			setIsLoading(false);
		}, 1000);
	}, []);

	// Handle date filter changes
	const handleDateFilterChange = (newDateRange) => {
		setDateRange(newDateRange);

		if (!stats) return;

		// Apply date filtering
		if (newDateRange.startDate && newDateRange.endDate) {
			const startDate = new Date(newDateRange.startDate);
			const endDate = new Date(newDateRange.endDate);

			const filteredVisitHistory = stats.visitHistory.filter((visit) => {
				const visitDate = new Date(visit.date);
				return visitDate >= startDate && visitDate <= endDate;
			});

			// Update filtered data
			setFilteredData({
				...stats,
				visitHistory: filteredVisitHistory,
			});
		} else {
			// Reset to original data if no date range is selected
			setFilteredData(stats);
		}
	};

	// Handle search term changes
	const handleSearchChange = (e) => {
		const term = e.target.value.toLowerCase();
		setSearchTerm(term);

		if (!stats || term === '') {
			// Reset to date-filtered data or original data
			handleDateFilterChange(dateRange);
			return;
		}

		// Apply search filtering on top of date filtering
		let dataToFilter = stats.visitHistory;

		// Apply date filter first if active
		if (dateRange.startDate && dateRange.endDate) {
			const startDate = new Date(dateRange.startDate);
			const endDate = new Date(dateRange.endDate);

			dataToFilter = dataToFilter.filter((visit) => {
				const visitDate = new Date(visit.date);
				return visitDate >= startDate && visitDate <= endDate;
			});
		}

		// Then apply search filter
		const filteredHistory = dataToFilter.filter(
			(visit) =>
				visit.provider.toLowerCase().includes(term) ||
				visit.hospital.toLowerCase().includes(term) ||
				visit.service.toLowerCase().includes(term)
		);

		setFilteredData({
			...stats,
			visitHistory: filteredHistory,
		});
	};

	// Status badge component
	const getStatusBadge = (waitTime) => {
		if (waitTime <= 10) {
			return <Badge bg='success'>On Time</Badge>;
		} else if (waitTime <= 30) {
			return <Badge bg='warning'>Slight Delay</Badge>;
		} else {
			return <Badge bg='danger'>Significant Delay</Badge>;
		}
	};

	// Loading state
	if (isLoading) {
		return (
			<div className='d-flex justify-content-center align-items-center vh-100'>
				<div
					className='spinner-border text-primary'
					role='status'>
					<span className='visually-hidden'>Loading...</span>
				</div>
			</div>
		);
	}

	return (
		<>
			<Header />
			<Sidebar additionalMenus={[{ label: 'WTTS Menu', items: wttsMenu }]} />
			<div className='main main-app p-3 p-lg-4'>
				{/* Breadcrumb Section */}
				<div className='d-flex justify-content-between align-items-center mb-4'>
					<Breadcrumb>
						<Breadcrumb.Item href='/'>Home</Breadcrumb.Item>
						<Breadcrumb.Item active>Wait Time Dashboard</Breadcrumb.Item>
					</Breadcrumb>
					<div>
						<Button
							variant='outline-primary'
							className='me-2'>
							<Calendar3 className='me-1' /> Export Report
						</Button>
					</div>
				</div>

				{/* Stat Cards Section */}
				<h5 className='mb-3'>Key Metrics</h5>
				<Row className='g-3 mb-4'>
					{/* Total Visits */}
					<Col
						xs={12}
						sm={6}
						md={3}>
						<StatCard
							title='Total Visits'
							value={filteredData.totalVisits}
							icon={<Person size={24} />}
							color='#1e3a8a'
						/>
					</Col>

					{/* Total Time Spent */}
					<Col
						xs={12}
						sm={6}
						md={3}>
						<StatCard
							title='Total Time Spent'
							value={`${filteredData.totalTimeSpent} mins`}
							icon={<Clock size={24} />}
							color='#1e40af'
						/>
					</Col>

					{/* Average Wait Time */}
					<Col
						xs={12}
						sm={6}
						md={3}>
						<StatCard
							title='Avg. Wait Time'
							value={`${filteredData.averageWaitTime} mins`}
							icon={<Stopwatch size={24} />}
							color='#1e4ed8'
							subtitle='Per Appointment'
						/>
					</Col>

					{/* On-Time Performance */}
					<Col
						xs={12}
						sm={6}
						md={3}>
						<StatCard
							title='On-Time Performance'
							value={`${filteredData.onTimePerformance}%`}
							icon={<ExclamationTriangle size={24} />}
							color='#2563eb'
						/>
					</Col>
				</Row>

				{/* Date Range Picker */}
				<DateRangePicker onDateChange={handleDateFilterChange} />

				{/* Visit History Section */}
				<Card className='shadow-sm mb-4'>
					<Card.Body>
						<div className='d-flex justify-content-between align-items-center mb-3'>
							<Card.Title className='mb-0'>Visit History</Card.Title>
							<Form.Group className='d-flex align-items-center'>
								<Search
									size={16}
									className='me-2 text-muted'
								/>
								<Form.Control
									type='text'
									placeholder='Search by provider, hospital or service...'
									value={searchTerm}
									onChange={handleSearchChange}
									style={{ maxWidth: '300px' }}
								/>
							</Form.Group>
						</div>

						<div className='table-responsive'>
							<table className='table table-hover'>
								<thead className='table-light'>
									<tr>
										<th>Date</th>
										<th>Provider</th>
										<th>Hospital</th>
										<th>Service</th>
										<th>Scheduled</th>
										<th>Actual</th>
										<th>Wait Time</th>
										<th>Status</th>
									</tr>
								</thead>
								<tbody>
									{filteredData.visitHistory.length > 0 ? (
										filteredData.visitHistory.map((visit, index) => (
											<tr key={visit.id || index}>
												<td>{visit.date}</td>
												<td>
													<div className='d-flex align-items-center'>
														<Person
															size={16}
															className='me-1 text-primary'
														/>
														{visit.provider}
													</div>
												</td>
												<td>
													<div className='d-flex align-items-center'>
														<Hospital
															size={16}
															className='me-1 text-primary'
														/>
														{visit.hospital}
													</div>
												</td>
												<td>{visit.service}</td>
												<td>{visit.scheduledTime}</td>
												<td>{visit.actualTime}</td>
												<td>{visit.waitTime} mins</td>
												<td>{getStatusBadge(visit.waitTime)}</td>
											</tr>
										))
									) : (
										<tr>
											<td
												colSpan='8'
												className='text-center py-4'>
												No visits matching your criteria
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</Card.Body>
				</Card>

				{/* Charts Section */}
				<Row className='mb-4'>
					<Col md={7}>
						<Card className='h-100 shadow-sm'>
							<Card.Body>
								<Card.Title>Monthly Visit Trend</Card.Title>
								<ResponsiveContainer
									width='100%'
									height={300}>
									<LineChart
										data={filteredData.visitTrend}
										margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
										<CartesianGrid strokeDasharray='3 3' />
										<XAxis dataKey='month' />
										<YAxis yAxisId='left' />
										<YAxis
											yAxisId='right'
											orientation='right'
										/>
										<Tooltip />
										<Legend />
										<Line
											yAxisId='left'
											type='monotone'
											dataKey='visits'
											name='Visits'
											stroke='#1e40af'
											strokeWidth={2}
											activeDot={{ r: 8 }}
										/>
										<Line
											yAxisId='right'
											type='monotone'
											dataKey='avgWaitTime'
											name='Avg Wait Time (mins)'
											stroke='#f59e0b'
											strokeWidth={2}
										/>
									</LineChart>
								</ResponsiveContainer>
							</Card.Body>
						</Card>
					</Col>

					<Col md={5}>
						<Card className='h-100 shadow-sm'>
							<Card.Body>
								<Card.Title>Hospital Performance</Card.Title>
								<ResponsiveContainer
									width='100%'
									height={300}>
									<BarChart
										data={filteredData.hospitalPerformance}
										margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
										layout='vertical'>
										<CartesianGrid strokeDasharray='3 3' />
										<XAxis type='number' />
										<YAxis
											dataKey='name'
											type='category'
											width={100}
										/>
										<Tooltip />
										<Legend />
										<Bar
											dataKey='avgWaitTime'
											name='Avg Wait Time (mins)'
											fill='#3b82f6'
										/>
										<Bar
											dataKey='onTimeRate'
											name='On-Time Rate (%)'
											fill='#10b981'
										/>
									</BarChart>
								</ResponsiveContainer>
							</Card.Body>
						</Card>
					</Col>
				</Row>

				{/* Delay Analysis Section */}
				<Card className='shadow-sm mb-4'>
					<Card.Body>
						<Card.Title className='mb-3'>Delay Analysis</Card.Title>
						<Row>
							<Col md={6}>
								<div className='mb-3'>
									<h6 className='mb-2'>Hospital with Longest Wait Times</h6>
									<div className='d-flex align-items-center p-3 bg-light rounded'>
										<div
											className='d-flex align-items-center justify-content-center me-3'
											style={{
												width: '48px',
												height: '48px',
												borderRadius: '12px',
												backgroundColor: '#dc2626',
												color: '#ffffff',
											}}>
											<Building size={24} />
										</div>
										<div>
											<h5 className='mb-0'>
												{filteredData.longestWaitHospital}
											</h5>
											<p className='mb-0 text-muted'>
												Consider scheduling appointments earlier in the day
											</p>
										</div>
									</div>
								</div>
							</Col>
							<Col md={6}>
								<div className='mb-3'>
									<h6 className='mb-2'>Provider with Most Delays</h6>
									<div className='d-flex align-items-center p-3 bg-light rounded'>
										<div
											className='d-flex align-items-center justify-content-center me-3'
											style={{
												width: '48px',
												height: '48px',
												borderRadius: '12px',
												backgroundColor: '#dc2626',
												color: '#ffffff',
											}}>
											<Person size={24} />
										</div>
										<div>
											<h5 className='mb-0'>
												{filteredData.providerWithMostDelays.name}
											</h5>
											<p className='mb-0 text-muted'>
												Delay rate:{' '}
												{filteredData.providerWithMostDelays.delayPercent}% of
												appointments
											</p>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>
			</div>
		</>
	);
};

export default Dashboard;
